<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <form @submit.prevent="submit">
      <section :style="{
        'background-image': 'url(' + banner_url + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100%',
        'background-position': 'center',
      }" class="
          free_listing-banner
          d-flex
          align-items-center
          justify-content-center
          
          position-relative
        ">
        <div class="container">
          <div class="banner_content-wrapper">
            <div class="banner_image__picker text-center pointer">
              <input type="file" name="file-input" id="file-input" class="
                  file-input__input
                  position-absolute
                  overflow-hidden
                  invisible
                " @change="onFilebanner" ref="banner" />
              <label class="
                  file-input__label
                  d-inline-flex
                  align-items-center
                  text-white
                  pointer
                  rounded
                  grey-bg
                  py-2
                  px-3
                  f-18
                  text-dark
                " for="file-input">
                <img src="@/assets/images/AddUser.svg" alt="Add User">
                <span class="f-16 fw-400 text-center">Upload <br> Iogo</span>
              </label>
            </div>
          </div>
        </div>
      </section>
      <!-- <img v-if="banner_url" :src="banner_url" /> -->

      <section class="listing_page-body py-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div class="card border">
                <div class="card-body">
                  <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="gray-text f-18">Restaurant Info</h2>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-Firstname-input">
                          Business Name
                        </label>
                        <input type="text" class="form-control rounded-pill" id="formrow-Firstname-input"
                          v-model.trim="$v.form.title.$model" placeholder="Enter Your First Name" />
                        <div class="error" v-if="$v.form.title.$error">
                          Title field is required
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-email-input">Email</label>
                        <input type="email" class="form-control rounded-pill" id="formrow-email-input"
                          v-model="form.email" placeholder="Enter Your Email Address" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label " for="formrow-Contact-input">
                          Contact No.
                        </label>
                        <input type="tel" class="form-control rounded-pill" id="formrow-Contact-input"
                          placeholder="(907) 555-0101" v-model="form.mobile" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label " for="Website">Website</label>
                        <input type="text" class="form-control rounded-pill" id="Website" placeholder="www.xyz.com"
                          v-model.trim="$v.form.website.$model" />
                        <div class="error" v-if="$v.form.website.$error">
                          Website field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">

                      <!-- Rating Select Field -->
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Rating</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select" aria-label="select example"
                          v-model="form.rating">
                          <option disabled :value=null selected>Select Rating (1-10)</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>

                      <!-- Ranking Select Field -->
                      <div class="mb-3">
                        <label class="form-label" for="formrow-ranking-select">Ranking</label>

                        <select class="form-select form-control rounded-pill" id="formrow-ranking-select" aria-label="select example"
                          v-model="form.ranking">
                          <option disabled :value=null selected>Select Ranking (1-3)</option>
                          <option v-for="n in 3" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="overview">Overview</label>
                        <textarea type="text" class="form-control form-control rounded-pill" id="overview"
                          placeholder="Business Overview" v-model="form.overview" />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="category">Category</label>
                        <!-- <select
                          v-model="form.category_id"
                          class="form-select"
                          id="category"
                          aria-label="select example"
                          @change="changeCategory(rm.category_id)"
                        >
                          <option disabled v-bind:value="null">
                            Select Category
                          </option>
                          <option
                            v-for="(category, index) in categories"
                            :key="index"
                            :value="category.id"
                          >
                            {{ category.title }}
                          </option>
                        </select> -->
                        <multiselect v-model="categorySelected" :multiple="true" :options="categoryOptions"
                          :preserve-search="true" label="title" track-by="title" @select="changeCategory($event)"
                          class="custom-dropdown" >
                        </multiselect>
                      </div>
                      <div class="mb-3">
                        <template>
                          <div class="">
                            <label class="form-label" for="formrow-rating-select">Location Type</label>
                            
                            <v-select 
                              v-model="selectedLocationTypes" 
                              :options="locationTypes" 
                              label="title" 
                              :getOptionLabel="getOptionLabel" 
                              @input="selectLocationType"
                              class="custom-dropdown"
                              multiple 
                              :close-on-select="false"
                            >
                              <!-- This is how to define the option slot correctly -->
                              <template v-slot:option="option">
                                <div class="d-flex align-items-center gap-2">
                                  <img :src="option.icon" alt="Icon" width="20" class="option-icon">
                                  <span>{{ option.title }}</span>
                                </div>
                              </template>
                            </v-select>
                          </div>
                        </template>
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">OFL Accolades</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select" aria-label="select example"
                          v-model="form.rating">
                          <option disabled :value=null selected>Select (1-10)</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                    </div>
                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="sub_category"
                          >Sub Category</label
                        >
                        <multiselect
                          v-model="selected"
                          :multiple="true"
                          :options="options"
                          :preserve-search="true"
                          label="title"
                          track-by="title"
                        >
                        </multiselect>
                      </div>
                    </div> -->

                    <!-- <div class="features_wrapper pb-4 col-12">
                       <h2 class="secondary-color f-24">Business Features</h2>
                      <div class="features_inner-wrapper d-flex flex-wrap">
                        <div
                          v-for="(item, index) in allFeature"
                          :key="index"
                          class="form-check features_inner pb-1 col-6 col-lg-4"
                        >
                        <img :id="'flexCheckChecked1' + index" v-if="item.image" height="25px" width="25px" :src="item.image" />
                        <span v-else>N/A</span>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="feature"
                            :value="item.id"
                            :id="'flexCheckChecked1' + index"
                          />
                          <label
                            class="features-label form-check-label f-14 pointer"
                            :for="'flexCheckChecked1' + index"
                            >{{ item.feature }}</label
                          >
                        </div>
                      </div> 
                    </div> -->
                  </div>
                  <!-- <input
                    @change="uploadLogo"
                    accept="image/*"
                    type="file"
                    id="logo"
                    class="file-input__input position-absolute overflow-hidden invisible"
                  />
                  <label
                    class="file-input__label d-inline-flex align-items-center text-white pointer rounded grey-bg py-2 px-3 f-18 text-dark"
                    for="logo"
                  >
                    <i class="uil-upload f-20 pe-2"></i>
                    <span class="f-20 fw-medium">Upload Image</span>
                  </label>-->
                  <!--------------------- Select user---------->
                  <div class="form-check">
                    <input
                        class="form-check-input fs-19"
                        type="checkbox"
                        v-model="form.is_member"
                        @change="
                              updateVisitStatus(
                                  'membership_status',
                                  form.is_member ? 'is_member' : 'not_member'
                              )
                          "
                        id="flexCheckDefault2"
                    />
                  </div>
                  <label
                      class="form-check-label fs-18 dark-text"
                      for="flexCheckDefault2"
                  >
                     OFL Partner
                  </label>
                  <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="gray-text f-18 fw-600">Select User</h2>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="mb-3">
                        <label for="category" class="form-label">User</label>
                        <select v-model="form.user_id" id="category" aria-label="select example"
                          class="form-select form-control rounded-pill">
                          <option disabled :value="null">
                            Please Select an User
                          </option>
                          <option v-for="(user, index) in users" :key="index" :value="user.id">
                            {{ user.full_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                
                  <!--------------------- Select user---------->
                  <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="gray-text f-18 fw-600">Select Price Rage</h2>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="mb-3">
                        <label for="category" class="form-label">Price Rage</label>
                        <select v-model="form.price_range" id="category" aria-label="select example"
                          class="form-select form-control rounded-pill">
                          <option disabled :value="null">
                            Please Select Price Rage
                          </option>
                          <option  value="$">
                            $
                          </option>
                          <option  value="$$">
                            $$
                          </option>
                          <option  value="$$$">
                            $$$
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- ------------ Select price range ------------ -->
                  <div class="row mb-2">
                    <div class="over-view__wrapper col-12">
                      <h2 class="gray-text f-18 fw-600">Social Profiles</h2>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="facebook">Facebook</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Facebook" aria-label="Facebook"
                          aria-describedby="addon-wrapping" class="form-control rounded-pill"
                          v-model="form.social.facebook" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="instagram">Instagram</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Instagram" aria-label="Instagram"
                          aria-describedby="addon-wrapping" v-model="form.social.instagram"
                          class="form-control rounded-pill" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="twitter">Twitter</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Twitter" aria-label="Twitter" aria-describedby="addon-wrapping"
                          class="form-control rounded-pill" v-model="form.social.twitter" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="google">Google</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Google" aria-label="Google" aria-describedby="addon-wrapping"
                          class="form-control rounded-pill" v-model="form.social.google" />
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Page Gallery---------->
                  <div class="features_gallery--wrapper ">
                    <h2 class="gray-text f-18 fw-600">Photo Gallery</h2>
                    <div class="images_wrapper-features-gallery row">
                      <div class="col-md-6">
                        <div class="image_wrapper">
                          <label for="media" class="f-14 fw-medium text-dark">Listing Photos*(JPEG,JPG,PNG)</label>
                          <UploadImages @changed="handleImages" />
                        </div>
                        <!--------------------- free listing Page Video---------->
                        <div class="video wrapper">
                          <h2 class="gray-text f-18 fw-600 mt-4">Video Gallery</h2>
                          <label for="media" class="f-14 fw-medium text-dark">Listing Videos*(Max Size 50MB)</label>
                          <span class="text-danger" v-if="videoError">Video Size Too Much(Max 50MB)</span>
                          <div class="gallery_video-wrapper mt-3 position-relative">
                            <div class="
                              video-file-input
                              position-relative
                              text-center
                              rounded
                              pointer
                            ">
                              <input @change="uploadVideo" accept="video/*" type="file" name="file-input"
                                id="file-input-video" class="file-input__input" ref="videoInput" />
                              <label for="file-input-video" class="
                                file-input__label
                                d-inline-flex
                                align-items-center
                                text-white
                                pointer
                             
                                grey-bg
                                py-2
                                px-3
                                f-18
                                text-dark
                              ">
                              <img src="@/assets/images/AddUser.svg" alt="Add User">
                                <span v-if="video_url.length >= 1" class="f-20 fw-medium">Upload More</span>
                                <span v-else class="f-20 fw-medium">Upload videos</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="features_gallery--wrapper dd">
                      <div :class="video_url.length >= 1 ? 'grey-bg my-4 p-md-4' : 'transparent'" class="
                        videos-show_main
                        d-flex
                        col-md-6
                        flex-wrap
                        rounded
                      ">
                        <div v-for="(single, index) in video_url" :key="index" class="
                          videos_wrapper-inner
                          col-md-6 col-12
                          position-relative
                        ">
                          <video :src="single" width="100%" height="100%" controls
                            class="video_styles rounded p-1"></video>
                          <span @click="removeVideo(index)" class="video_del-icon pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="16px" height="16px">
                              <path fill="#f78f8f"
                                d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z" />
                              <path fill="#c74343"
                                d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z" />
                              <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-134.999 20 20)" />
                              <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-45.001 20 20)" />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Faq---------->
                  <div class="listing__faq py-4">
                    <div class="faq_inner-listing">
                      <h2 class="gray-text f-18 fw-600">
                        Frequently Asked Questions
                      </h2>
                      <div style="padding-bottom: 10px" class="faq_box-wrapper">
                        <div style="margin-bottom: 10px" v-for="(item, index) in faq" :key="index" class="
                            faq_form-box-inner
                             border-secondary
                            rounded
                            p-3
                          ">
                          <!-- <form class="listing_faq-form"> -->
                          <input type="text" name="listing_faq-question" id="listing_faq-question"
                            placeholder="Question" class="w-100 py-2 form-control rounded-pill "
                            v-model="item.question" />
                          <div class="input-group">
                            <textarea class="form-control mt-3 w-100 rounded-pill" aria-label="With textarea" rows="4"
                              cols="50" id="listing_overview" placeholder="Answer" v-model="item.answer"></textarea>
                          </div>
                          <!-- <div class="list_add-faq pt-4">
                            <div
                              class="
                                list_add-faq-inner
                                p-3
                                rounded
                                grey-bg
                                pointer
                              "
                            >
                              <h3 class="text-dark f-16 fw-semibold m-0">
                                <i
                                  class="
                                    fa fa-plus fa-solid fa-sharp
                                    text-dark
                                    pe-2
                                    f-20
                                  "
                                ></i
                                >Add Another
                              </h3>
                            </div>
                          </div> -->
                          <div class="py-2"> <a class="btn btn-danger" v-show="index != 0" @click="removeFaqBox(index)">
                              Remove
                            </a></div>
                        </div>
                      </div>
                      <a @click="addMore()" class="btn bg-tranparent orange-text f-18 p-0">
                        Add Another +
                      </a>
                    </div>
                  </div>
                  <!-- Dish Section -->
                  <div class="dish_inner-listing pb-4">
                    <h2 class="gray-text f-18 fw-600">Add Menu Details</h2>

                    <div style="padding-bottom: 10px" class="dish_box-wrapper">
                      <div style="margin-bottom: 10px" v-for="(dish, index) in dishes" :key="index"
                        class="dish_form-box-inner  border-secondary rounded pt-3">
                        <!-- Dish Image Upload -->
                        <div class="form-group mb-3">
                          <label for="dish_image" class="form-label"></label>
                          <input type="file" class="form-control rounded-pill" id="dish_image"
                            @change="onImageChange($event, index)" />

                          <img v-if="dish.previewImage" :src="dish.previewImage" alt="Dish Image Preview"
                            class="img-fluid mt-2" style="max-height: 150px;" />
                        </div>

                        <!-- Dish Name -->
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Dish Name"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="dish.name" />
                          </div>

                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Dish Price"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="dish.price" />
                          </div>

                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_type" id="dish_type" placeholder="Dish Type"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="dish.type" />
                          </div>
                        </div>

                        <!-- Remove Button -->
                        <div class=" p1  rounded-pill border-orange fit-content">
                          <a class="btn bg-orange rounded-pill text-white px-4 f-18 remove-hover" v-show="index != 0"
                            @click="removeDishBox(index)">
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                    <a @click="addMoreDish()" class="btn bg-tranparent orange-text f-18 p-0">Menu +</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card border shadow-none">
                <div class="card-body">
                  <!-- <form class="side-bar_listing-form"> -->
                  <!--------------------- free listing Sidemenu Location---------->
                  <div class="listings-location_main rounded">
                    <div class="location_inner-wrappper rounded">
                      <div class="location_info-wrapper">
                        <div class="row">
                          <div class="col-md-12">
                            <AddressFields :modelValue="address" @update:modelValue="address = $event" />
                            <!-- <label class="form-label" for="street address">
                              Street
                              Address
                            </label>-->
                            <!-- <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Street Address"
                                aria-label="Street Address"
                                v-model="form.address"
                                aria-describedby="addon-wrapping"
                              />
                            </div>-->
                          </div>
                        </div>
                        <!--   <div class="row">
                          <div class="col-md-12">
                            <label class="form-label" for="City">City</label>
                          </div>-->
                        <!-- <div class="col-md-12 mb-3">
                            <div class="listing-state">
                              <input
                                type="text"
                                name="location_listing-city"
                                placeholder="Business Email"
                                class="form-control w-100"
                                v-model="form.city"
                              />
                            </div>
                          </div>-->
                        <!-- <div class="col-md-12">
                            <label class="form-label" for="State">State</label>
                          </div>-->
                        <!-- <div class="col-md-12 mb-3">
                            <div class="listing-state">
                              <input
                                type="text"
                                name="location_listing-city"
                                placeholder="Business Email"
                                v-model="form.state"
                                class="form-control w-100"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <label class="form-label" for="County">County</label>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="listing-state">
                              <input
                                type="text"
                                name="location_listing-city"
                                placeholder="Business Email"
                                class="form-control w-100"
                                v-model="form.country"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <label class="form-label" for="Zip Code">Zip Code</label>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="listing-state">
                              <input
                                type="text"
                                name="location_listing-city"
                                placeholder="Business Email"
                                class="form-control w-100"
                                v-model="form.zip_code"
                              />
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="listing-state">
                              <select
                                name="listings_states"
                                id="cars"
                                class="form-select w-100 form-control"
                              >
                                <option value="volvo">TX</option>
                                <option value="saab">CA</option>
                                <option value="opel">OP</option>
                                <option value="audi">CT</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="listing-country">
                               <select
                                name="listings_country"
                                id="cars"
                                class="form-select w-100 form-control"
                              >
                                <option value="volvo">US</option>
                                <option value="saab">AU</option>
                                <option value="opel">TI</option>
                              </select>
                            </div>
                          </div> 
                        </div>-->
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Sidemenu HOurs---------->
                  <div class="listings-hours_main my-3">
                    <div class="location_listing-hours-wrapper rounded">
                      <div class="hours_header d-flex align-items-center justify-content-between flex-wrap">

                        <label class="features-label form-check-label gray-text f-18 pointer pe-4"
                          for="availibilityHours">Availability Hours</label>

                        <div class="hour_toggle">
                          <input @click="hoursStatus" class="form-check-input" v-model="form.availability_hours"
                            type="checkbox" id="availibilityHours" />
                          <label class="features-label form-check-label text-secondary f-12 pointer ps-2"
                            for="availibilityHours">Hide Hours</label>
                        </div>
                      </div>

                      <div class="
                          hours-header
                          row
                          justify-content-between
                          align-items-center
                          py-2
                        ">
                        <div v-if="form.availability_hours" class="col-4">
                          <!-- <h2 class="text-dark f-16 fw-bold m-0">Hours</h2> -->
                        </div>
                      </div>
                      <div v-if="form.availability_hours" class="hours-listing-body">
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>Monday</span>
                          </div>

                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="monday-start-time" type="time"
                              name="start-time" v-model="time.mondayFrom" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1" id="appt-end-time" type="time"
                              v-model="time.mondayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>Tuesday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-start-time" type="time"
                              name="start-time" v-model="time.tuesdayFrom" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-end-time" type="time"
                              name="end-time" v-model="time.tuesdayTo" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>Wednesday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-start-time" type="time"
                              name="start-time" v-model="time.wednesdayFrom" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1" id="appt-end-time" type="time"
                              v-model="time.wednesdayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>thursday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-start-time" type="time"
                              v-model="time.thursdayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-end-time" type="time"
                              name="end-time" v-model="time.thursdayTo" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>Friday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-start-time" type="time"
                              v-model="time.fridayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-end-time" type="time"
                              v-model="time.fridayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>Saturday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-start-time" type="time"
                              v-model="time.saturdayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-end-time" type="time"
                              v-model="time.saturdayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            ">
                            <span>Sunday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-start-time" type="time"
                              v-model="time.sundayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-md-4">
                            <input class="form-control pointer p-1 time-field" id="appt-end-time" type="time"
                              v-model="time.sundayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cta_submit button text-end border-orange fit-content rounded-pill p1 float-end">
                    <SubmitButton :processing="processing" type="submit" class="
                        btn btn-primary
                        waves-effect waves-light
                        py-2
                        px-5
                        w-auto
                      " text="Add Listing" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import UploadImages from "vue-upload-drop-images";
import AddressFields from "../AddressFields.vue";
import { required } from "vuelidate/lib/validators";
import { VueCsvImport } from "vue-csv-import";
import Multiselect from "vue-multiselect";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
export default {
  page: {
    title: "Claim Business",
    meta: [
      {
        name: "approved",
        content: appConfig.description,
      },
    ],
  },
  components: {
    UploadImages,
    Layout,
    PageHeader,
    AddressFields,
    Multiselect,
    vSelect,
  },
  data() {
    return {
      videoError: false,
      categoryOptions: [],
      categorySelected: [],
      selected: [],
      options: [],
      selectedLocationTypes:[],
      title: "Add Resturants",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Add Restaurants",
          active: true,
        },
      ],
      processing: false,
      faq: [
        {
          question: null,
          answer: null,
        },
      ],
      dishes: [{
        image: null,
        name: '',
        price: '',
        type: '',
        previewImage: null
      }
      ],
      dish_media: [],

      time: {
        mondayFrom: null,
        mondayTo: null,
        tuesdayFrom: null,
        tuesdayTo: null,
        wednesdayFrom: null,
        wednesdayTo: null,
        thursdayFrom: null,
        thursdayTo: null,
        fridayFrom: null,
        fridayTo: null,
        saturdayFrom: null,
        saturdayTo: null,
        sundayFrom: null,
        sundayTo: null,
      },
      video_url: [],
      banner_url: null,
      banner: "",
      logo: "",
      video: [],
      categories: null,
      subCategories: null,
      users: null,
      address: {
        longitude: null,
        latitude: null,
        city: "",
        state: "",
        country: "",
        address: "",
        zip_code: "",
        place_id: null,
      },
      allFeature: [],
      feature: [],
      media: [],
      locationTypes:[],
      form: {
        availability_hours: true,
        title: null,
        city: null,
        state: null,
        country: null,
        address: null,
        zip_code: null,
        price_range:null,
        email: null,
        mobile: null,
        rating:null,
        ranking:null,
        is_member:null,
        website: null,
        longitude: null,
        latitude: null,
        category_id: [],
        sub_category_id: [],
        selectedLocationTypeIds:[],
        user_id: null,
        overview: null,
        place_id: null,
        social: {
          facebook: null,
          instagram: null,
          twitter: null,
          google: null,
        },
        time: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      website: {
        required,
      },
    },
  },
  watch: {
    parseCsv(newValue, oldValue) {
      console.log(newValue);
      return (this.newRecord = newValue.slice(1));
    },
  },
  mounted() {
    this.allCategory();
    this.allFeatures();
    this.allUser();
    this.allLocationType();
  },
  methods: {
    onFilebanner(e) {
      const file = e.target.files[0];
      this.banner = this.$refs.banner.files[0];
      this.banner_url = URL.createObjectURL(file);
    },
    selectMedia(e) {
      const file = e.target.files[0];
      console.log(file);
    },
    allFeatures() {
      this.$axios
        .get("feature")
        .then((response) => {
          this.allFeature = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit() {
      this.selected.forEach((element) => {
        this.form.sub_category_id.push(element.id);
      });
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        if (this.form.availability_hours == true) {
          this.form.time.monday = this.time.mondayFrom + "-" + this.time.mondayTo;
          this.form.time.tuesday =
            this.time.tuesdayFrom + "-" + this.time.tuesdayTo;
          this.form.time.wednesday =
            this.time.wednesdayFrom + "-" + this.time.wednesdayTo;
          this.form.time.thursday =
            this.time.thursdayFrom + "-" + this.time.thursdayTo;
          this.form.time.friday = this.time.fridayFrom + "-" + this.time.fridayTo;
          this.form.time.saturday =
            this.time.saturdayFrom + "-" + this.time.saturdayTo;
          this.form.time.sunday = this.time.sundayFrom + "-" + this.time.sundayTo;
        }
        let formData = new FormData();
        this.processing = true;
        this.form.zipcode = this.address.zipcode;
        this.form.city = this.address.city;
        this.form.state = this.address.state;
        this.form.country = this.address.country;
        this.form.longitude = this.address.longitude;
        this.form.latitude = this.address.latitude;
        this.form.place_id = this.address.place_id;
        // this.form.media = this.form.media.map((media) => media.name);

        formData.append("banner", this.banner);
        this.form.address = this.address.address;
        formData.append("address", JSON.stringify(this.address));
        formData.append("form", JSON.stringify(this.form));
        formData.append("faq", JSON.stringify(this.faq));
        formData.append("dishes", JSON.stringify(this.dishes));
        // let dishes_images = [];
        for (let i = 0; i < this.dishes.length; i++) {
          if (this.dishes[i].image) {
            formData.append(`dish_media[${i}]`, this.dishes[i].image);
          }
        }
        // formData.append("video", this.video);
        for (var v = 0; v < this.video.length; v++) {
          let file = this.video[v];
          formData.append("video[" + v + "]", file);
        }
        for (var i = 0; i < this.media.length; i++) {
          let file = this.media[i];
          formData.append("media[" + i + "]", file);
        }
        formData.append("feature", JSON.stringify(this.feature));
        // console.log(this.form);
        this.$axios
          .post("add-business-listing", formData)
          .then((response) => {
            // console.log(response);
            this.$router.push({ path: "/business/listing" });
            this.triggerSwal(response.data, "success");
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          });
      }
    },
    allCategory() {
      this.$axios
        .get("categories")
        .then((response) => {
          this.categories = response.data.data;
          this.categoryOptions = response.data.data;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    changeCategory(val) {
      this.form.category_id.push(val.id);
      // console.log(val);
      // this.categorySelected.forEach(element => {
      // this.options=[];
      this.$axios.get("subCategories/" + val.id).then(response => {
        response.data.data.forEach(element => {
          this.options.push(element);
        })
      })
      // console.log(this.options);
      // });
    },
    allUser() {
      this.$axios
        .get("all-users-for-list")
        .then((response) => {
          this.users = response.data.data;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    uploadLogo(e) {
      this.logo = e.target.files[0];
    },
    uploadVideo(e) {
      let video = e.target.files[0];
      if (video.size >= 5000000) {
        return this.videoError = true
      }
      this.videoError = false;
      this.video.push(video);
      let video_url = URL.createObjectURL(video);
      this.video_url.push(video_url);
      if (this.video_url.length <= 0) {
        e.target.value = '';
      }
      console.log(this.video_url);
    },
    handleImages(files) {
      console.log(files);
      this.media = [];
      for (let i = 0; i < files.length; i++) {
        this.media.push(files[i]);
      }
      console.log(this.media);
    },
    removeVideo(index) {
      this.video_url.splice(index, 1);
      this.$refs.videoInput.value = ''

    },
    addMore() {
      this.faq.push({
        question: null,
        answer: null,
      });
      // let obj = {};
      // obj.question = this.question;
      // obj.answer = this.answer;
      // this.faq.push(obj);
      // this.question = null;
      // this.answer = null;
      // obj = {};
    },
    removeFaqBox(index) {
      this.faq.splice(index, 1);
    },
    addMoreDish() {
      this.dishes.push({ image: null, name: '', type: '' });
    },
    removeDishBox(index) {
      this.dishes.splice(index, 1);
    },
    onImageChange(event, index) {
      const file = event.target.files[0];
      this.dishes[index].image = file;
      if (file) {
        this.dishes[index].previewImage = URL.createObjectURL(file);
      }
    },
    updateVisitStatus(statusType, status)
    {
      this.form.is_member = status;
      // alert( this.form.is_member);
    },
    hoursStatus(){
      this.form.availability_hours=!this.form.availability_hours
      // console.log(this.form.availability_hours);
    },
    allLocationType() {
      this.$axios
        .get("location-type")
        .then((response) => {
          this.locationTypes = response.data.data;
          console.log(this.locationTypes,'location-type');
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getOptionLabel(option) {
      return option.title;
    },
    selectLocationType(selectedOptions) {
      this.form.selectedLocationTypeIds =  selectedOptions.map(option => option.id);
    }
  },
};

</script>
